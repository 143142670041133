import { Action } from '../../../@types/Action.type.ts';
import { Project_ProjectContext_Left } from '../../../applications/projects/constants/projectActionTypes.ts';
import { Shared_CurrentProjectId_Updated } from '../../constants/sharedActionTypes.ts';

const initialState: Uuid = '';

export function currentProjectId(state: Uuid = initialState, action: Action): Uuid {
  switch (action.type) {
    case Shared_CurrentProjectId_Updated: {
      return action.payload.currentProjectId;
    }

    case Project_ProjectContext_Left: {
      return initialState;
    }

    default:
      return state;
  }
}
